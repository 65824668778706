import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-type',
  templateUrl: './question-type.component.html',
  styleUrls: ['./question-type.component.scss']
})
export class QuestionTypeComponent implements OnInit {

  constructor() { }
  categories= [
    {
      name: 'Questions and Answers in the Risale-i Nur',
      imageURL: 'https://firebasestorage.googleapis.com/v0/b/quran-nur.appspot.com/o/unnamed22.jpg?alt=media&token=0b93c74b-3880-41ce-a9b0-b92230456e90',
      id: 'our_questions'
    },
    {
      name: 'Questions from Readers',
      imageURL: 'https://firebasestorage.googleapis.com/v0/b/quran-nur.appspot.com/o/unnamed2323.jpg?alt=media&token=0d6e772c-b21e-4252-9cce-c55f29dcd13f',
      id: 'users_questions'
    }
];
  ngOnInit() {
  }

}
