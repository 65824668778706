import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-archive-results',
  templateUrl: './archive-results.component.html',
  styleUrls: ['./archive-results.component.scss']
})
export class ArchiveResultsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private afs: AngularFirestore) { }
  tag: any;
  articles: any[] = [];
  questions: any[] = [];
  ngOnInit() {
    this.tag = this.route.snapshot.params['date'];
    console.log(this.tag);
    this.afs.collection('Articles', ref => ref.where('archives', '==', this.tag)).get().subscribe((res) => {
      res.docs.forEach((doc) => {
        this.articles.push({ id: doc.id, ...doc.data() });
      });
    });
    this.afs.collection('Questions', ref => ref.where('archives', '==', this.tag)).get().subscribe((res) => {
      res.docs.forEach((doc) => {
        this.questions.push({ id: doc.id, ...doc.data() });
      });
    });
  }

}
