import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireLiteFirestore } from 'angularfire-lite';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as firebase from 'firebase';

@Component({
  selector: 'app-qadetails',
  templateUrl: './qadetails.component.html',
  styleUrls: ['./qadetails.component.scss']
})
export class QADetailsComponent implements OnInit {

  constructor(private firestore: AngularFireLiteFirestore, private route: ActivatedRoute, private titleService: Title) { }

  id: string;
  increment = firebase.firestore.FieldValue.increment(1);
  question: any;
  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.firestore.read('Questions/' + this.id).subscribe((data) => {
      this.question = data;
    });
    
    this.titleService.setTitle(this.question.title);
    this.viewCounter();
  }

  viewCounter(){
    this.firestore.update('Questions/' + this.id, {
      viewCount: this.increment
    });
  }


}
