import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private afs: AngularFirestore) { }
  tag: any;
  articles: any[] = [];
  ngOnInit() {
    this.tag = this.route.snapshot.params['tag'];
    console.log(this.tag);
    this.afs.collection('Articles', ref => ref.where('type', '==', 'Our Article').where('tags', 'array-contains', this.tag)).get().subscribe((res) => {
      res.docs.forEach((doc) => {
        this.articles.push({ id: doc.id, ...doc.data() });
      });
    });
  }

}
