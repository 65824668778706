import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './Shared/navbar/navbar.component';
import { HomeComponent } from './Pages/home/home.component';
import { GalleryComponent } from './Pages/gallery/gallery.component';
import { ArticlesComponent } from './Pages/articles/articles.component';
import { ArticleDetailsComponent } from './Pages/article-details/article-details.component';
import { AboutQuranNurComponent } from './Pages/about-quran-nur/about-quran-nur.component';
import { AboutSaidNursiComponent } from './Pages/about-said-nursi/about-said-nursi.component';
import { QAListComponent } from './Pages/qalist/qalist.component';
import { QADetailsComponent } from './Pages/qadetails/qadetails.component';
import { BookListComponent } from './Pages/book-list/book-list.component';
import { BookDetailsComponent } from './Pages/book-details/book-details.component';
import { CategoriesComponent } from './Pages/categories/categories.component';
import { TermsOfUseComponent } from './Pages/terms-of-use/terms-of-use.component';
import { GalleryDetailsComponent } from './Pages/gallery-details/gallery-details.component';
import { FooterComponent } from './Shared/footer/footer.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireLite } from 'angularfire-lite';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { environment } from '../environments/environment';
import { GsPlayerModule} from 'gs-player';
import { QuranicTasfirComponent } from './Pages/quranic-tasfir/quranic-tasfir.component';
import { SearchResultsComponent } from './Pages/search-results/search-results.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ArchiveResultsComponent } from './Pages/archive-results/archive-results.component';
import { CategoryPipe } from './category.pipe';
import { QuestionCategoriesComponent } from './Pages/question-categories/question-categories.component';
import { QuestionTypeComponent } from './Pages/question-type/question-type.component';

@NgModule({
  declarations: [
    AppComponent,
    AppComponent,
    NavbarComponent,
    HomeComponent,
    GalleryComponent,
    ArticlesComponent,
    ArticleDetailsComponent,
    AboutQuranNurComponent,
    AboutSaidNursiComponent,
    QAListComponent,
    QADetailsComponent,
    BookListComponent,
    BookDetailsComponent,
    CategoriesComponent,
    TermsOfUseComponent,
    GalleryDetailsComponent,
    FooterComponent,
    QuranicTasfirComponent,
    SearchResultsComponent,
    SafeHtmlPipe,
    ArchiveResultsComponent,
    CategoryPipe,
    QuestionCategoriesComponent,
    QuestionTypeComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'web' }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    GsPlayerModule,
    AngularFirestoreModule,
    AngularFireLite.forRoot(environment.firebaseConfig),
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
