import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Shared/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-question-categories',
  templateUrl: './question-categories.component.html',
  styleUrls: ['./question-categories.component.scss']
})
export class QuestionCategoriesComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, public dataService: DataService) { }
  type = '';
  categories: any[];
  ngOnInit() {
    this.type = this.route.snapshot.params['type'];
    console.log(this.type);
    if(this.type === 'our_questions')
    {
      this.dataService.getOurQuestionsCategories();
      this.categories = this.dataService.ourQuestionCategories;
    }
    else if(this.type === 'users_questions'){
      this.dataService.getYourQuestionsCategories();
      this.categories = this.dataService.yourQuestionCategories;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
}
