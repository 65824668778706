import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireLiteFirestore } from 'angularfire-lite';

@Component({
  selector: 'app-about-said-nursi',
  templateUrl: './about-said-nursi.component.html',
  styleUrls: ['./about-said-nursi.component.scss']
})
export class AboutSaidNursiComponent implements OnInit {

  constructor(private afs: AngularFireLiteFirestore) { }

  about: any = "";
  ngOnInit() {
    this.afs.read('About/About').subscribe((res) => {
      this.about = res;
    });
  }

}
