// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCXyn9Kk6BXmRNTtjm3PwccJ0BUEQ_8D5Q",
    authDomain: "quran-nur.firebaseapp.com",
    databaseURL: "https://quran-nur.firebaseio.com",
    projectId: "quran-nur",
    storageBucket: "quran-nur.appspot.com",
    messagingSenderId: "339808586935",
    appId: "1:339808586935:web:9403ec7ff8e024d0ca9132",
    measurementId: "G-W1ERJZKR0E"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
