import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(public dataService: DataService, private router: Router) { }

  ngOnInit() {
  }

  public selectedItem(e): void {
    if (this.dataService.tags.indexOf(e.target.value !== -1)) {
      this.router.navigateByUrl('searchResults/' + e.target.value);
    }
  }
}
