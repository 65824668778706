import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-qalist',
  templateUrl: './qalist.component.html',
  styleUrls: ['./qalist.component.scss']
})
export class QAListComponent implements OnInit {

  constructor(private afs: AngularFirestore, private route: ActivatedRoute) { }

  questions: any[] = [];
  category: string;
  ngOnInit() {
    this.questions = [];
    this.category = this.route.snapshot.params['category'];
    this.afs.collection('Questions', ref => ref.where('category', '==', this.category)).get().subscribe((res) => {
      res.docs.forEach((doc) => {
        this.questions.push({id: doc.id, ...doc.data()});
      });
    });
  }

}
