import { Component, OnInit } from '@angular/core';
import { AngularFireLiteFirestore } from 'angularfire-lite';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gallery-details',
  templateUrl: './gallery-details.component.html',
  styleUrls: ['./gallery-details.component.scss']
})
export class GalleryDetailsComponent implements OnInit {

  constructor(private firestore: AngularFireLiteFirestore, private route: ActivatedRoute) { }

  gallery: any;
  id: string;
  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    console.log(this.id);
    this.firestore.read('Galleries/' + this.id).subscribe((data) => {
      this.gallery = data;
    });
  }

}
