import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Shared/data.service';

@Component({
  selector: 'app-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.scss']
})
export class BookListComponent implements OnInit {

  
  constructor(public dataService: DataService) { }
  type = 'our_articles';
  ngOnInit() {
    this.dataService.getBooks();
  }


}
