import { Pipe, PipeTransform } from '@angular/core';
import { DataService } from './Shared/data.service';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {

  constructor(private dataService: DataService) {
       
  }
  transform(val): any {
    console.log(val);
    console.log(this.dataService.categories);
    var category = this.dataService.categories.filter(x => x.id == val)[0];
    console.log(category);
    return category.name;
  }

}
