import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './Pages/home/home.component';
import { ArticlesComponent } from './Pages/articles/articles.component';
import { ArticleDetailsComponent } from './Pages/article-details/article-details.component';
import { AboutQuranNurComponent } from './Pages/about-quran-nur/about-quran-nur.component';
import { AboutSaidNursiComponent } from './Pages/about-said-nursi/about-said-nursi.component';
import { BookListComponent } from './Pages/book-list/book-list.component';
import { CategoriesComponent } from './Pages/categories/categories.component';
import { GalleryComponent } from './Pages/gallery/gallery.component';
import { QADetailsComponent } from './Pages/qadetails/qadetails.component';
import { QAListComponent } from './Pages/qalist/qalist.component';
import { TermsOfUseComponent } from './Pages/terms-of-use/terms-of-use.component';
import { BookDetailsComponent } from './Pages/book-details/book-details.component';
import { GalleryDetailsComponent } from './Pages/gallery-details/gallery-details.component';
import { QuranicTasfirComponent } from './Pages/quranic-tasfir/quranic-tasfir.component';
import { SearchResultsComponent } from './Pages/search-results/search-results.component';
import { ArchiveResultsComponent } from './Pages/archive-results/archive-results.component';
import { QuestionTypeComponent } from './Pages/question-type/question-type.component';
import { QuestionCategoriesComponent } from './Pages/question-categories/question-categories.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent
  },
  {
    path: 'articles/:type/:category', component: ArticlesComponent
  },
  {
    path: 'questions/:type/:category', component: QAListComponent
  },
  {
    path: 'articleDetails/:id', component: ArticleDetailsComponent
  },
  {
    path: 'about_quran-nur', component: AboutQuranNurComponent
  },
  {
    path: 'about_bediuzzaman_said_nursi', component: AboutSaidNursiComponent
  },
  {
    path: 'summaries', component: BookListComponent
  },
  {
    path: 'bookDetails/:id', component: BookDetailsComponent
  },
  {
    path: 'categories/:type', component: CategoriesComponent
  },
  {
    path: 'categories/your_articles', component: CategoriesComponent
  },
  {
    path: 'gallery/:category', component: GalleryComponent
  },
  {
    path: 'searchResults/:tag', component: SearchResultsComponent
  },
  {
    path: 'galleryDetails/:id', component: GalleryDetailsComponent
  },
  {
    path: 'questionDetails/:id', component: QADetailsComponent
  },
  {
    path: 'questionTypes', component: QuestionTypeComponent
  },
  {
    path: 'questionCategories/:type', component: QuestionCategoriesComponent
  },
  {
    path: 'questions', component: QAListComponent
  },
  {
    path: 'questions/:type/:category', component: QAListComponent
  },
  {
    path: 'quranicTafsir', component: QuranicTasfirComponent
  },
  {
    path: 'termsOfUse', component: TermsOfUseComponent
  },
  {
    path: 'archives/:date', component: ArchiveResultsComponent
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
