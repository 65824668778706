import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  constructor(private afs: AngularFirestore, private route: ActivatedRoute) { }
  type: string;
  category: string;
  articleType: string;
  articles: any[] = [];

  ngOnInit() {
    this.type = this.route.snapshot.params['type'];
    if(this.type == 'our_articles') {
      this.articleType = 'Our Article';
    } else {
      this.articleType = 'Users Article';
    }
    this.category = this.route.snapshot.params['category'];
    this.getArticles();
  }

  async getArticles(): Promise<any> {
    if(this.articles.length === 0) {
      var articles = [];
      this.afs.collection('Articles', ref => ref.where('category', '==', this.category).where('type', '==', this.articleType).orderBy('date')).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          articles.push({id: doc.id, ...doc.data()});
        });
      });
      this.articles = articles.sort(function(a, b) {
        return a.name.localeCompare(b.name)
    });
      return articles;
    } else {
      return this.articles;
    }
  }

}
