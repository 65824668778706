import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-quranic-tasfir',
  templateUrl: './quranic-tasfir.component.html',
  styleUrls: ['./quranic-tasfir.component.scss']
})
export class QuranicTasfirComponent implements OnInit {

  constructor(private afs: AngularFirestore) { }
  data: any;
  ngOnInit() {  
    this.afs.collection('Tasfir').doc('Tasfir').get().subscribe((res) => {
      this.data = res.data();
    });

    console.log((new Date()).toLocaleString('en', {month: 'long'}).toLowerCase() + '_' + new Date().getFullYear());
  }

}
