import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  categories: any[] = [];
  yourArticleCategories: any[] = [];
  ourQuestionCategories: any[] = [];
  yourQuestionCategories: any[] = [];
  books: any[] = [];
  tags: any[] = [];
  constructor(private afs: AngularFirestore) {
    
  }

  async getOurArticleCategories(): Promise<any> {
    if(this.categories.length === 0) {
      var categories = [];
      this.afs.collection('Categories', ref => ref.orderBy('order', 'asc').where('type', '==', 'Our Article')).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          categories.push({id: doc.id, ...doc.data()});
        });
      });
      this.categories = categories.sort(function(a, b) {
        return a.name.localeCompare(b.name)
    });
      return categories;
    } else {
      return this.categories;
    }
  }
  
  async getYourArticleCategories(): Promise<any> {
    if(this.yourArticleCategories.length === 0) {
      var categories = [];
      this.afs.collection('Categories', ref => ref.orderBy('order', 'asc').where('type', '==', 'Users Article')).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          categories.push({id: doc.id, ...doc.data()});
        });
      });
      this.yourArticleCategories = categories.sort(function(a, b) {
        return a.name.localeCompare(b.name)
    });
      return categories;
    } else {
      return this.yourArticleCategories;
    }
  }

  async getYourQuestionsCategories(): Promise<any> {
    if(this.yourQuestionCategories.length === 0) {
      var categories = [];
      this.afs.collection('Categories', ref => ref.orderBy('order', 'asc').where('type', '==', 'Users Question')).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          categories.push({id: doc.id, ...doc.data()});
        });
      });
      this.yourQuestionCategories = categories.sort(function(a, b) {
        return a.name.localeCompare(b.name)
    });
      return categories;
    } else {
      return this.yourQuestionCategories;
    }
  }

  async getOurQuestionsCategories(): Promise<any> {
    if(this.ourQuestionCategories.length === 0) {
      var categories = [];
      this.afs.collection('Categories', ref => ref.orderBy('order', 'asc').where('type', '==', 'Our Question')).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          categories.push({id: doc.id, ...doc.data()});
        });
      });
      this.ourQuestionCategories = categories.sort(function(a, b) {
        return a.name.localeCompare(b.name)
    });
      return categories;
    } else {
      return this.ourQuestionCategories;
    }
  }


  async getBooks(): Promise<any> {
    if(this.books.length === 0) {
      var books = [];
      this.afs.collection('Books', ref => ref.orderBy('order')).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          books.push({id: doc.id, ...doc.data()});
        });
      });
      this.books = books.sort(function(a, b) {
        return a.name.localeCompare(b.name)
    });
      return books;
    } else {
      return this.books;
    }
  }

  async getTags(): Promise<any> {
    if(this.tags.length === 0) {
      this.afs.collection('Tags').doc('Tags').get().subscribe((res) => {
        this.tags = res.data().tags;
      });
      return this.tags;
    } else {
      return this.tags;
    }
  }
}
