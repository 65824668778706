import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.getTerms();
  }

  termsOfUse: any = '';

  getTerms(){
    this.afs.collection('Terms').doc('Terms').get().subscribe(res => {
      this.termsOfUse = res.data().terms;
    });
  }

}
