import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DataService } from 'src/app/Shared/data.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private afs: AngularFirestore, public dataService: DataService) { }



  latestArticles: any[] = [];
  popularArticles: any[] = [];
  articles: any[] = [];
  divineNames: any [] = [];
  knowingAllah: any [] = [];
  quranicAyat: any [] = [];
  rasulullah: any [] = [];
  marksOfIslam: any [] = [];
  archives: any[] = [];
  wordsOfWisdom: any [] = [];

  archiveNames = [];
  monthNames = [ "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December" ];
        diff(from, to) {
          var arr = [];
          var datFrom = new Date('1 ' + from);
          var datTo = new Date('1 ' + to);
          var fromYear =  datFrom.getFullYear();
          var toYear =  datTo.getFullYear();
          var diffYear = (12 * (toYear - fromYear)) + datTo.getMonth();
      
          for (var i = datFrom.getMonth(); i <= diffYear; i++) {
              arr.push(this.monthNames[i%12] + " " + Math.floor(fromYear+(i/12)));
          }
      
          return arr;
      }
  ngOnInit() {

    this.archiveNames = this.diff('September 2020', (this.monthNames[new Date(Date.now()).getMonth()]) + ' ' + new Date(Date.now()).getFullYear().toString())

    
  



    if(window){
      this.dataService.getOurArticleCategories();
      this.dataService.getBooks();
      this.dataService.getTags();
      Promise.all([
        this.dataService.getBooks(),
        this.dataService.getOurArticleCategories(),
        this.dataService.getTags()
      ]);
      
      this.afs.collection('Articles', ref => ref.where('type', '==', 'Our Article').orderBy('date', 'desc').limit(1)).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          this.latestArticles.push({ id: doc.id, ...doc.data() });
        });
      });
      this.afs.collection('Articles', ref => ref.where('type', '==', 'Our Article').orderBy('viewCount', 'desc').limit(1)).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          this.popularArticles.push({ id: doc.id, ...doc.data() });
        });
      });
      this.afs.collection('Archives').doc('Archives').get().subscribe((res) => {
          this.archives = res.data().archives;
      }); 
      this.afs.collection('Galleries', ref => ref.where('category', '==', 'divine_names').orderBy('date', 'desc').limit(1)).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          this.divineNames.push({ id: doc.id, ...doc.data() });
        });
      });
      this.afs.collection('Galleries', ref => ref.where('category', '==', 'knowing_allah').orderBy('date', 'desc').limit(1)).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          this.knowingAllah.push({ id: doc.id, ...doc.data() });
        });
      });
      this.afs.collection('Galleries', ref => ref.where('category', '==', 'quranic_ayat').orderBy('date', 'desc').limit(1)).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          this.quranicAyat.push({ id: doc.id, ...doc.data() });
        });
      });
      this.afs.collection('Galleries', ref => ref.where('category', '==', 'character_and_qualities_of_rasulullah').orderBy('date', 'desc').limit(1)).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          this.rasulullah.push({ id: doc.id, ...doc.data() });
        });
      });
      this.afs.collection('Galleries', ref => ref.where('category', '==', 'marks_of_islam').orderBy('date', 'desc').limit(1)).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          this.marksOfIslam.push({ id: doc.id, ...doc.data() });
        });
      });
      this.afs.collection('Galleries', ref => ref.where('category', '==', 'words_of_wisdom').orderBy('date', 'desc').limit(1)).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          this.wordsOfWisdom.push({ id: doc.id, ...doc.data() });
        });
      });
    }
  }}



