import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireLiteFirestore } from 'angularfire-lite';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Title, Meta, SafeResourceUrl } from '@angular/platform-browser';
import * as firebase from 'firebase';
import {DomSanitizer} from '@angular/platform-browser';
import { PlayerFile, PlayerTheme, PlayerThemeDark, PlayerThemeLight } from 'gs-player';
import { AngularFirestore } from '@angular/fire/firestore';



@Component({
  selector: 'app-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss'],
})

export class ArticleDetailsComponent implements OnInit {
  
  name:string;
  video: any;
  baseUrl:string = 'https://www.youtube.com/embed/';
  url: SafeResourceUrl;
  public files: Array<PlayerFile>;
  public playerTheme = {
    // HTML/CSS Color Name, Hex Code #RRGGBB, Decimal Code (R,G,B)
    primary: 'rgb(174,58,59)',
    secondary: 'grey'
  };

  constructor(private afs: AngularFirestore, private sanitizer: DomSanitizer, private titleService: Title, private metaService: Meta,private route: ActivatedRoute,public firestore: AngularFireLiteFirestore, private fb: FormBuilder) { 
    this.sendCommentForm = fb.group({
      'userName': new FormControl('', []),
      'userSurname': new FormControl('', []),
      'userEmail': new FormControl('', [Validators.required]),
      'userComment': new FormControl('', [Validators.required]),
    });
  }

  id: string;
  article: any;
  test = "Nowadays it is common cause that many people, especially the youth, are seeking to understand Islam through asking a multitude of questions. Bediuzzaman Said Nursi in his contemporary Qur’anic tafsir, the Risale-i Nur addresses this issue. He very profoundly says “Curiosity is the teacher of knowledge”. This implies that rather than discouraging curiosity and questions, these should be encouraged with the aim of deepening our understanding about Islam and the Sharia, to gain knowledge of Allah’s Essence, Attributes and Divine Names and to strengthen and grow our love for Him.";
  sendCommentForm: FormGroup;
  increment = firebase.firestore.FieldValue.increment(1);
  
  comments: any[] = [];
  ngOnInit() {
    
    this.id = this.route.snapshot.params['id'];
    this.firestore.read('Articles/' + this.id).subscribe((data) => {
      this.article = data;
      this.titleService.setTitle(this.article.title);
      this.files = [
        {
          url: this.article.voiceUrl,
          name: '',
          artist: '',
          album: 'Our Articles'
        }
      ];
      this.video = {id: this.article.youtubeId};
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + this.video.id);

      this.metaService.addTags([
        {name: 'description', content: this.article.summary},
        {name: 'robots', content: 'index, follow'},
      ]);
  });
  
  this.viewCounter();
  this.getComments();
  
  }
  
  viewCounter(){
    this.firestore.update('Articles/' + this.id, {
      viewCount: this.increment
    });
  }

  onSubmit(){
    this.afs.collection('Comments').add({
      name: this.sendCommentForm.get('userName').value + ' ' + this.sendCommentForm.get('userSurname').value,
      email: this.sendCommentForm.get('userEmail').value,
      comment: this.sendCommentForm.get('userComment').value,
      date: Date.now(),
      valid: false,
      articleId: this.id,
    }).then((val) => {
      if(this.sendCommentForm.valid){
        window.alert('Your comment saved to database. We will review and show your comment if it is eligible.');
      this.sendCommentForm.get('userName').setValue('');
      this.sendCommentForm.get('userEmail').setValue('');
      this.sendCommentForm.get('userComment').setValue('');
      this.sendCommentForm.get('userSurname').setValue('');
      this.sendCommentForm.setValidators([]);
      } else {
        window.alert('Please fill the comment form correctly.');
      }
    }).catch((err) => {
      window.alert(err.toString());
    });
  }

  async getComments(): Promise<any> {
      var articles = [];
      this.afs.collection('Comments', ref => ref.where('articleId', '==', this.id).where('valid', '==', true).orderBy('date')).get().subscribe((res) => {
        res.docs.forEach((doc) => {
          articles.push({id: doc.id, ...doc.data()});
        });
      });
      this.comments = articles.sort(function(a, b) {
        return a.name.localeCompare(b.name)
    });
      return articles;
    }

}
