import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/Shared/data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute, public dataService: DataService) { }
  type = 'our_articles';
  categories: any[];
  ngOnInit() {
    this.type = this.route.snapshot.params['type'];
    console.log(this.type);
    if(this.type === 'our_articles')
    {
      this.dataService.getOurArticleCategories();
      this.categories = this.dataService.categories;
    }
    else if(this.type === 'your_articles'){
      this.dataService.getYourArticleCategories();
      this.categories = this.dataService.yourArticleCategories;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
}
