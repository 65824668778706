import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-about-quran-nur',
  templateUrl: './about-quran-nur.component.html',
  styleUrls: ['./about-quran-nur.component.scss']
})
export class AboutQuranNurComponent implements OnInit {

  constructor(private afs: AngularFirestore) { }

  about: any = "";
  ngOnInit() {
    this.afs.collection('About').doc('About').get().subscribe((res) => {
      this.about = res.data();
    });
  }

}
