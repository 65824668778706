import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor(private afs: AngularFirestore, private route: ActivatedRoute) { }

  category: string;
  galleries: any[] = [];
  ngOnInit() {
    this.category = this.route.snapshot.params['category'];
    this.afs.collection('Galleries', ref => ref.where('category', '==', this.category)).get().subscribe((res) => {
      res.docs.forEach((doc) => {
        this.galleries.push({id: doc.id, ...doc.data()});
      });
    });
  }

}
