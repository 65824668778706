import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFireLiteFirestore } from 'angularfire-lite';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-book-details',
  templateUrl: './book-details.component.html',
  styleUrls: ['./book-details.component.scss']
})
export class BookDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute, private firestore: AngularFireLiteFirestore, private metaService: Meta, private titleService: Title) { }
  id: string;
  book: any;
  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.firestore.read('Books/' + this.id).subscribe((data) => {
      this.book = data;
    });
    this.metaService.addTags([
      { name: 'description', content: this.book.description},
      { name: 'robots', content: 'index, follow' },
    ]);
    this.titleService.setTitle(this.book.title);
  }

  openLink(){
     window.open(this.book.link, '_blank');
  }
}